<template>
  <div class="wrapper">
    <div class="title">{{ video.media_name }}</div>
    <div class="play-num">
      <div class="play-num-icon"></div>
      <div class="play-num-content">{{ video.resources_read_num }}次播放</div>
      <div
        class="play-num-label"
        v-for="(item, index) in video.category"
        :key="index"
      >
        {{ item.category_name }}
      </div>
    </div>
    <div class="desc" @click="descActive = !descActive">
      <div class="desc-title">内容简介</div>
      <div ref="content" class="desc-content" :class="{active: descActive}">
        {{ video.media_desc }}
        <div class="desc-content-icon" v-show="showMore"></div>
      </div>
    </div>
    <div class="list-title">专辑列表</div>
    <swiper ref="videoSwiper" class="list" :options="swiperOption">
      <swiper-slide
        class="item-list"
        v-for="(item,index) in videoList"
        :key="index"
      >
        <div class="item-list-content" :class="{active: index===currentIndex}">{{ item.media_name }}</div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'VideoDetailDesc',
  props: {
    video: {
      type: Object,
      default () {
        return {}
      }
    },
    videoList: {
      type: Array,
      default () {
        return []
      }
    },
    currentIndex: {
      type: Number,
      default: -1
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      descActive: true,
      showMore: false,
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        on: {
          slideChangeTransitionEnd: function () {
            console('end')
            // this.realIndexs = this.realIndex
          },
          click: (swiper) => {
            if (swiper.clickedIndex >= 0) {
              this.changeVideo(swiper.clickedIndex)
            }
          }
        }
      }
    }
  },
  methods: {
    changeVideo (index) {
      if (index === this.currentIndex) {
        return false
      }
      this.$emit('changeVideo', index)
    }
  },
  watch: {
    video (video) {
      this.$nextTick(() => {
        if (this.$refs.content.scrollHeight - this.$refs.content.clientHeight > 10) {
          this.showMore = true
        } else {
          this.showMore = false
        }
      })
    },
    descActive () {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 100%
  padding-bottom 30px
  background rgba(255, 255, 255, 1)
  border-bottom 10px solid rgba(245, 245, 244, 1)

  .title
    width 720px
    padding-left 30px
    height 90px
    line-height 94px
    font-size 34x
    color rgba(51, 51, 51, 1)
    font-weight bold
    no-wrap()

  .play-num
    display flex
    align-items center
    flex-wrap wrap
    width 720px
    padding-left 30px

    .play-num-icon
      width 23px
      height 14px
      margin-bottom 10px
      bg-image('~@/assets/img/video/player-num')
      background-size 100% 100%
      background-repeat no-repeat

    .play-num-content
      width 150px
      padding-left 20px
      height 30px
      margin-bottom 10px
      line-height 34px
      font-size 22px
      color rgba(153, 153, 153, 1)
      no-wrap()

    .play-num-label
      height 30px
      padding 0 12px
      line-height 34px
      font-size 20px
      color rgba(245, 151, 1, 1)
      border 1px solid rgba(245, 151, 1, 1)
      border-radius 6px
      margin-bottom 10px
      margin-right 10px

  .desc
    width 100%

    .desc-title
      width 720px
      padding-left 30px
      height 30px
      line-height 34px
      margin-top 42px
      border-left 8px solid rgba(179, 53, 58, 1)
      font-size 30px
      color rgba(51, 51, 51, 1)
      font-weight bold

    .desc-content
      position relative
      width 690px
      padding 24px 30px 0 30px
      line-height 34px
      font-size 26px
      color rgba(51, 51, 51, 1)

      &.active
        no-wrap2(3)

        .desc-content-icon
          display block

      .desc-content-icon
        position absolute
        right 44px
        bottom 0
        width 18px
        padding 0 4px
        height 30px
        background rgba(255, 255, 255, 1)
        bg-image('~@/assets/img/book-detail/more')
        background-size 18px 10px
        background-repeat no-repeat
        background-position center
        display none

  .list-title
    width 690px
    padding 30px
    font-size 30px
    font-weight bold

  .list
    display flex
    width 690px
    padding 0 30px
    height 112px

    .item-list
      width 166px
      height 60px
      padding 26px
      background rgba(245, 245, 244, 1)
      border-radius 10px
      margin-right 10px

      .item-list-content
        width 100%
        height 100%
        line-height 34px
        font-size 24px
        color rgba(153, 153, 153, 1)
        no-wrap2(2)

        &.active
          color rgba(179, 53, 58, 1)
</style>
