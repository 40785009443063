<template>
  <div class="wrapper">
    <div class="header">
      <div class="title">视频榜单</div>
      <div class="more" @click="getMore">
        <div class="more-content">查看更多</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="slide"
        v-for="(item,index) in list"
        :key="index"
      >
        <img class="slide-img" :src="item.resources_cover"/>
        <div class="slide-label"></div>
        <div class="slide-desc">
          <div class="content">{{ item.resources_name }}</div>
          <div class="icon"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'VideoDetailRanking',
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      descActive: true,
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        on: {
          click: (swiper) => {
            if (this.$route.params.guid === this.list[swiper.clickedIndex].resources_guid) {
              return false
            } else {
              let index = this.$route.path.indexOf('/video-detail')
              let path = this.$route.path.substring(0, index)
              path += '/video-detail/' + this.list[swiper.clickedIndex].resources_guid + '/' + this.list[swiper.clickedIndex].resources_name
              this.$router.push(path)
            }
          }
        }
      }
    }
  },
  methods: {
    getMore () {
      this.$router.push('/home/video/resources_read_num')
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 750px
  padding 20px 0 50px 0
  background rgba(255, 255, 255, 1)

  .header
    display flex
    align-items center
    width 690px
    padding 0 30px 0 22px
    height 30px
    border-left 8px solid rgba(179, 53, 58, 1)

    .title
      font-size 30px
      font-weight bold
      line-height normal

    .more
      display flex
      align-items center
      height 30px
      margin-left auto

      .more-content
        line-height normal
        font-size 26px
        color rgba(153, 153, 153, 1)

      .more-icon
        width 10px
        height 18px
        margin-left 10px
        bg-image('~@/assets/img/personal/more')
        background-size 100% 100%
        background-repeat no-repeat

  .swiper
    display flex
    width 720px
    padding-left 30px
    height 222px
    margin-top 26px

    .slide
      position relative
      width 318px
      height 222px
      margin-right 14px
      border-radius 10px
      overflow hidden

      .slide-img
        width 100%
        height 100%

      .slide-desc
        position absolute
        left 0
        bottom 0
        display flex
        align-items center
        width 318px
        height 62px
        background rgba(0, 0, 0, .6)

        .content
          padding-left 14px
          width 260px
          height 30px
          line-height 34px
          font-size 26px
          color rgba(255, 255, 255, 1)
          no-wrap()

        .icon
          width 30px
          height 30px
          margin-left auto
          margin-right 10px
          bg-image('~@/assets/img/video/video-play')
          background-size 100% 100%
          background-repeat no-repeat
</style>
