<template>
  <div class="wrapper">
    <img class="share-close" @click="shareClose" src="@/assets/img/book-detail/share-close@3x.png" alt=""/>
    <div class="share-html" ref="share" id="share">
      <img class="share-bg" src="@/assets/img/book-detail/share-bg@3x.png" alt=""/>
      <div class="info">
        <div class="time">{{ year }}<br>{{ month }}{{ date }}</div>
        <div class="pic">
          <img class="pic-img" :src="picBase64" alt=""/>
        </div>
        <div class="bottom">
          <div class="title">{{ name }}</div>
        </div>
      </div>
      <div class="info-bottom">
        <div class="user-info">
          <div class="pointer">
            <img class="pointer-img" src="@/assets/img/book-detail/pointer@3x.png" alt=""/>
          </div>
          <div class="user">
            <div class="user-name">我是 {{ userName }}</div>
            <div class="share-text">我是码上悦读读者<br>邀请您与我一起观看视频</div>
          </div>
        </div>
        <div class="qr-code">
          <img class="qr-code-bg" src="@/assets/img/book-detail/qrcode@3x.png" alt=""/>
          <img class="qr-code-img" :src="qrCode">
        </div>
      </div>
    </div>
    <div class="share-canvas">
      <img :src="canvasImageUrl" class="share-canvas-img" alt=""/>
    </div>
    <div class="btn" v-show="showCanvas">长按保存图片</div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { getQrCode } from '@/api/Common'
import { LOCAL } from '@/utils/const'
import { Toast } from 'vant'
import { imgToBase64 } from '@/api/Uploader'

export default {
  name: 'ActivityShare',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    picUrl: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    userGuid: {
      type: String,
      default: ''
    },
    agencyGuid: {
      type: String,
      default: ''
    },
    addr: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      canvasImageUrl: '',
      year: '',
      month: '',
      date: '',
      qrCode: '',
      showCanvas: false,
      picBase64: ''
    }
  },
  mounted () {
    let nowDate = new Date()
    this.year = nowDate.getFullYear()
    this.month = nowDate.getMonth() + 1
    this.date = nowDate.getDate()
    if (this.month >= 1 && this.month <= 9) {
      this.month = '0' + this.month
    }
    if (this.date >= 0 && this.date <= 9) {
      this.date = '0' + this.date
    }
  },
  methods: {
    shareClose () {
      this.$emit('shareClose')
    }
  },
  watch: {
    async show (show) {
      if (!this.canvasImageUrl) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        const toBase64Res = await imgToBase64({
          url: this.picUrl
        })
        if (toBase64Res.code !== 200) {
          Toast.fail(toBase64Res.msg)
        }
        this.picBase64 = toBase64Res.data
        await getQrCode({
          qr_str: `${LOCAL}/#/home/video/create_time/video-detail/${this.$route.params.guid}/视频?agency_guid=${this.agencyGuid}&guide=${this.userGuid}`
        }).then(res => {
          if (res.code === 200) {
            this.qrCode = res.data
            this.$nextTick(() => {
              html2canvas(document.getElementById('share'), {
                backgroundColor: null,
                useCORS: true,
                tainttest: true,
                logging: true,
                scale: 4
              }).then((canvas) => {
                let imageUrl = canvas.toDataURL('image/png', 1.0)
                this.canvasImageUrl = imageUrl
                this.showCanvas = true
                Toast.clear()
              })
            })
          } else {
            Toast.fail(res.data)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  justify-content center
  flex-wrap wrap
  align-items center
  width 750px
  padding-top 30px

  .share-close
    position absolute
    z-index 100
    top 50px
    right 100px
    width 32px
    height 32px

  .share-html
    position fixed
    top: -1000px
    width 610px

    .share-bg
      width 100%

    .info
      position absolute
      top 10px
      left 10px
      width 590px
      height 670px
      background #3f4c68

      .time
        display flex
        justify-content center
        align-items center
        width 80px
        padding 10px
        margin 30px auto
        font-size 24px
        letter-spacing 4px
        color rgba(255, 255, 255, 1)
        border 1px solid rgba(255, 255, 255, 1)
        border-radius 10px

      .pic
        width 520px
        height 300px
        margin 50px auto
        padding 6px
        background rgba(255, 255, 255, 1)
        box-shadow 2px 5px 10px 0px rgba(4, 0, 0, 0.32)

        .pic-img
          width 100%
          height 100%

      .bottom
        position absolute
        bottom 0
        display flex
        justify-content center
        align-items center
        flex-wrap wrap
        width 590px
        padding 30px 0
        background rgba(0, 0, 0, 0.22)

        .title
          width 500px
          line-height 36px
          text-align center
          font-size 30px
          color rgba(255, 255, 255, 1)
          font-weight bold

    .info-bottom
      position absolute
      bottom 0
      display flex
      align-items center
      width 610px
      height 200px

      .user-info
        display flex
        flex-wrap wrap
        justify-content center
        align-items center
        width 370px
        height 180px

        .pointer
          width 22px
          height 180px

          .pointer-img
            width 22px
            height 22px
            margin-top 22px

        .user
          display flex
          flex-wrap wrap
          justify-content center
          align-items center
          width 300px
          padding-left 20px
          height 180px

          .user-name
            width 300px
            font-size 30px
            font-weight bold
            color rgba(51, 51, 51, 1)
            no-wrap()

          .share-text
            width 300px
            line-height 40px
            font-size 26px
            color rgba(51, 51, 51, 1)

      .qr-code
        position relative
        width 220px
        height 176px

        .qr-code-bg
          position absolute
          top 0
          left 0
          width 100%
          height 100%

        .qr-code-img
          position absolute
          top 6px
          left 6px
          width 164px
          height 164px

  .share-canvas
    width 610px

    .share-canvas-img
      width 100%
      height 100%

  .btn
    display flex
    justify-content center
    align-items center
    width 610px
    padding 16px 0
    margin-top 30px
    line-height normal
    font-size 30px
    font-weight bold
    color rgba(255, 255, 255, 1)
    border-radius 40px
    background rgba(179, 53, 58, 1)
</style>
