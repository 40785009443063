<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="video-detail">
      <scroll ref="scroll" class="scroll" :data="videoList">
        <div>
          <video-detail-header
            :video="currentVideo"
            :favorites-active="favoritesActive"
            @favorites="favorites"
            @share="share" v-on:endend="endend"
          ></video-detail-header>
          <video-detail-desc
            :video="currentVideo"
            :video-list="videoList"
            :current-index="currentIndex"
            @changeVideo="changeVideo"
            @refresh="refresh"
          ></video-detail-desc>
          <video-detail-like :like="like" @changeLike="changeLike"></video-detail-like>
          <video-detail-ranking :list="list"></video-detail-ranking>
        </div>
      </scroll>
      <div class="toanswer" @click="endend">去答题</div>
      <overlay
        class="overlay"
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="currentVideo?currentVideo.media_cover:''"
          :name="currentVideo?currentVideo.media_name:''"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          @shareClose="showShare=false"
        ></share>
      </overlay>
       <overlay :show="showSignFlag">
        <sign-overlay @close="showSignFlag = false"></sign-overlay>
      </overlay>
    </div>
  </transition>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import VideoDetailHeader from './components/Header'
import VideoDetailDesc from './components/Desc'
import VideoDetailLike from './components/Like'
import VideoDetailRanking from './components/Ranking'
import { getAudios, getResourcesPageList, setCUserLog } from '@/api/Resources'
import { mapGetters, mapMutations } from 'vuex'
import { appInit } from '@/utils/init'
import { Overlay, Toast } from 'vant'
import { setCUserFavorites } from '@/api/Cuser'
import Share from './components/Share'
import SignOverlay from '../common/SignOverlay'
export default {
  name: 'ActVideoDetail',
  computed: {
    currentVideo () {
      return this.videoList[this.currentIndex]
    },
    ...mapGetters([
      'userGuid',
      'agencyGuid',
      'userName',
      'userCityName'
    ])
  },
  props: ['name', 'guid'],
  components: {
    Scroll,
    VideoDetailHeader,
    VideoDetailDesc,
    VideoDetailLike,
    VideoDetailRanking,
    Overlay,
    Share,
    SignOverlay
  },
  mounted () {
    document.title = this.name
    this.setPlaying(false)
    this._videoInit()
    setTimeout(() => {
      console.log('tiao')
      this.$emit('_hassee')
    }, 606000)
  },
  beforeDestroy () {
    clearInterval(this.gameTimer)
    clearInterval(this.gameTimer2)
  },
  data () {
    return {
      videoList: [],
      currentIndex: -1,
      favoritesActive: false,
      like: [],
      list: [],
      showShare: false,
      showSignFlag: false
    }
  },
  methods: {
    endend () {
      console.log('tiao')
      this.$emit('_hassee')
    },
    async _videoInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      const audioRes = await getAudios({
        resources_guid: this.guid,
        c_user_guid: this.userGuid
      })
      if (audioRes.code === 200) {
        this.videoList = audioRes.data.list
        this.currentIndex = audioRes.data.current_index
        this.favoritesActive = this.videoList[this.currentIndex].favorites
      } else if (audioRes.code === 401) {
        appInit(this.agencyGuid, this.$route.path)
      } else if (audioRes.code === 402) {
        Toast.clear()
        this.$router.push({
          name: 'Login',
          params: {
            link: this.$route.path
          }
        })
      } else {
        return Toast.fail(audioRes.msg)
      }
      let categoryGuid = ''
      if (this.currentVideo) {
        if (this.currentVideo.category.length) {
          categoryGuid = this.currentVideo.category[0].category_guid
        }
      }
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 3,
        category_guid: categoryGuid,
        rand: true,
        not_in_guid: this.guid
      }).then(res => {
        if (res.code === 200) {
          this.like = res.data.list
          this.refresh()
        }
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 10,
        field: 'resources_read_num'
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list
          this.refresh()
        }
      })
      this.$nextTick(() => {
        this.$refs.scroll.scrollTo(0, 0)
      })
      this.refresh()
      Toast.clear()
    },
    changeVideo (index) {
      console.log('index--', index)
      this.currentIndex = index
    },
    favorites () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.currentVideo.resources_guid,
        favorites_type: 3,
        favorites_status: this.favoritesActive ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !this.favoritesActive
        }
        Toast.clear()
      })
    },
    share () {
      this.showShare = true
    },
    changeLike () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let categoryGuid = ''
      if (this.currentVideo) {
        if (this.currentVideo.category.length) {
          categoryGuid = this.currentVideo.category[0].category_guid
        }
      }
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        page: 1,
        limit: 3,
        category_guid: categoryGuid,
        rand: true,
        not_in_guid: this.guid
      }).then(res => {
        if (res.code === 200) {
          this.like = res.data.list
        }
        this.refresh()
        Toast.clear()
      })
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING'
    })
  },
  watch: {
    currentIndex () {
      setCUserLog({
        resources_guid: this.currentVideo.resources_guid,
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        c_user_location: this.userCityName,
        resources_ext: this.currentVideo.media_guid,
        resources_type: 3
      })
    },
    $route (to, from) {
      if (this.$route.name === 'VideoDetail' || this.$route.name === 'BooksDetailVideoDetail' || this.$route.name === 'HomeVideoDetail' || this.$route.name === 'SignInVideoDetail') {
        document.title = this.name
        this._videoInit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.video-detail
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)
  .toanswer{
    width: 183px;
    height: 62px;
    line-height:62px;
    text-align:center;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid #FFFFFF;
    font-weight: 500;
    font-size: 30px;
    color: #2650FC;
    position: absolute;
    top: 87px;
    right: 25px;
  }
  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden

  .overlay
    z-index 200
</style>
