<template>
  <div class="wrapper">
    <div class="header">
      <div class="back" @click="back">
        <div class="back-icon"></div>
        <div class="back-content">返回</div>
      </div>
      <div class="top-icon">
        <div class="icon-block" @click="favorites">
          <div class="icon icon-favorites"
               :class="{active: favoritesActive}"
          >
          </div>
          <div class="icon-name">收藏</div>
        </div>
        <div class="icon-block" @click="share">
          <div class="icon icon-share"></div>
          <div class="icon-name">分享</div>
        </div>
      </div>
    </div>
    <div class="video" ref="videoPlayer"></div>
  </div>
</template>

<script>
import Player from 'xgplayer'
import HlsPlayer from 'xgplayer-hls.js'

export default {
  name: 'VideoDetailHeader',
  props: {
    video: {
      type: Object,
      default () {
        return {}
      }
    },
    favoritesActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    onPlayerEnded ($event) {
      console.log('end---111')
    },
    back () {
      let index = this.$route.path.indexOf('/video-detail')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    favorites () {
      this.$emit('favorites')
    },
    share () {
      this.$emit('share')
    }
  },
  watch: {
    video (video) {
      if (!video) {
        return false
      }
      let options = {
        el: this.$refs.videoPlayer,
        url: video.media_file,
        fluid: true, // 自适应父级宽高
        'x5-video-player-type': 'h5', // 微信同层播放
        playsinline: true, // 开启ios和微信的内联模式
        playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速播放
        crossOrigin: true,
        autoplay: true,
        defaultPlaybackRate: 1, // 初始倍速
        closeVideoTouch: true, // 关闭video触发touchend事件后视频切换播放/暂停状态
        poster: video.media_cover // 封面图
      }
      this.$nextTick(() => {
        if (this.player) {
          this.player.destroy(true)
          this.player.on('destroy', () => {
            this.$nextTick(() => {
              if (video.media_ext === 'm3u8') {
                this.player = new HlsPlayer(options)
              } else {
                this.player = new Player(options)
              }
            })
          })

          this.player.on('ended', () => {
            console.log('播放结束，即将播放下一个2')
            this.$emit('endend')
          })
        } else {
          if (video.media_ext === 'm3u8') {
            this.player = new HlsPlayer(options)
          } else {
            this.player = new Player(options)
          }

          this.player.on('ended', () => {
            console.log('播放结束，即将播放下一个1')
            this.$emit('endend')
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%
  height 400px

  .video
    width 100%
    padding-top 400px !important

  .header
    display flex
    width 100%
    height 84px

    .back
      display flex
      align-items center
      width 159px
      height 84px

      .back-icon
        width 16px
        height 28px
        margin-left 43px
        bg-image('~@/assets/img/video-detail/back')
        background-size 100% 100%
        background-repeat no-repeat

      .back-content
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-left 10px

    .top-icon
      display flex
      align-items center
      margin-left auto

      .icon-block
        display flex
        align-items center
        width 124px
        height 28px
        line-height normal
        font-size 26px
        color rgba(51, 51, 51, 1)

        .icon
          width 28px
          height 28px
          margin-right 10px
          background-size 100% 100%
          background-repeat no-repeat

          &.icon-favorites
            bg-image('~@/assets/img/video-detail/icon-favorites')

          &.icon-share
            bg-image('~@/assets/img/video-detail/icon-share')

          &.active
            bg-image('~@/assets/img/books-detail/icon-favorites-active')
</style>
