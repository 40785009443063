<template>
  <div class="wrapper">
    <div class="like-title">
      <div class="title-content">猜你喜欢</div>
      <div class="rand">
        <div class="rand-icon"></div>
        <div class="rand-content" @click="changeLike">换一换</div>
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in like"
        :key="index"
        @click="selectItem(item)"
      >
        <div class="item-cover">
          <img class="cover-img" :src="item.resources_cover"/>
        </div>
        <div class="item-info">
          <div class="info-title">{{ item.resources_name }}</div>
          <div class="info-desc">{{ item.resources_desc }}</div>
          <div class="play-num">
            <div class="play-num-icon"></div>
            <div class="play-num-content">{{ item.resources_read_num }}次播放</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoDetailLike',
  props: {
    like: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    changeLike () {
      this.$emit('changeLike')
    },
    selectItem (item) {
      let index = this.$route.path.indexOf('/video-detail')
      let path = this.$route.path.substring(0, index)
      path += '/video-detail/' + item.resources_guid + '/' + item.resources_name
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 750px
  padding-bottom 30px
  border-bottom 10px solid rgba(245, 245, 244, 1)
  background rgba(255, 255, 255, 1)

  .like-title
    display flex
    align-items center
    width 720px
    padding-left 22px
    height 30px
    border-left 8px solid rgba(179, 53, 58, 1)
    margin-top 26px

    .title-content
      line-height normal
      font-size 30px
      color rgba(51, 51, 51, 1)
      font-weight bold

  .rand
    display flex
    align-items center
    padding-right 30px
    height 30px
    margin-left auto

    .rand-icon
      width 22px
      height 22px
      bg-image('~@/assets/img/refresh')
      background-size 100% 100%
      background-repeat no-repeat

    .rand-content
      padding-left 14px
      font-size 26px
      color rgba(153, 153, 153, 1)
      line-height normal

  .list
    display table
    width 690px
    padding 0 30px

    .item
      display flex
      width 690px
      margin-top 20px

      .item-cover
        width 318px
        height 222px
        border-radius 10px
        overflow hidden

        .cover-img
          width 100%
          height 100%

      .item-info
        display table
        width 348px
        padding-left 24px
        height 222px

        .info-title
          width 348px
          height 30px
          line-height 34px
          font-size 26px
          color rgba(51, 51, 51, 1)
          margin-top 32px
          no-wrap()

        .info-desc
          width 348px
          height 60px
          line-height 34px
          font-size 24px
          color rgba(153, 153, 153, 1)
          margin-top 20px
          no-wrap2(2)

        .play-num
          display flex
          align-items center
          width 348px
          height 30px
          margin-top 44px

          .play-num-icon
            width 23px
            height 14px
            bg-image('~@/assets/img/video/player-num')
            background-size 100% 100%
            background-repeat no-repeat

          .play-num-content
            height 26px
            padding-left 10px
            line-height 30px
            font-size 22px
            color rgba(153, 153, 153, 1)
            no-wrap()
</style>
